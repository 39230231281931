<template>
    <div class="text-white">
      <h1>Contact Page</h1>
      <p>Get in touch with us!</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "ContactPage",
  };
  </script>
  