import { createApp } from 'vue'
import App from './App.vue'
import router from "./views/router";



import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"


const app = createApp(App)


//Tıkladığımız sayfanın title bilgisini browserda gösteriyoruz
router.beforeEach((to, from, next) => {
    // Eğer meta.title varsa, document.title'ı o başlığa ayarla
    document.title = to.meta.title || ''; // Varsayılan başlık belirlenir
    next();
  });
//Tıkladığımız sayfanın title bilgisini browserda gösteriyoruz

app.use(router);


app.mount('#app')