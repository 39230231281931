<template>

    <div class="menu-main">

        <!-- Yan Menü -->
        <div class="w3-sidebar w3-bar-block w3-border-right"  id="mySidebar">
            <!-- v-show="isSidebarOpen" -->
        <div class="close-cont text-end">
            <a onclick="w3_close()" class="w3-large cls-btn">&times;</a>
        </div>
        <div class="side-head mb-2">
            <img :src="sideBarImg" alt="foto" class="head-img">
            <h5 class="ps-2 mb-0">
                {{ header }}
            </h5>
        </div>
        <div class="side-link pt-2 pb-2">
            <ul>
                <li v-for="item in menuItems" :key="item.name">
                    <router-link :to="item.link" class="w3-bar-item w3-button">
                        <div class="d-flex justify-content-start align-items-center cust-hvr pb-3">
                            <div class="icon-div ps-3">
                                <i :class="[item.icon, 'me-3','icon-color']"></i>
                            </div>
                            <div class="ps-3">
                                {{ item.name }}
                            </div>
                            
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
            
            
            
            
            
        </div>

        <!-- Ana Başlık ve Menü Açma Butonu -->
      
    </div>
</template>

<script>
export default {
    name: "LeftMenu",
    data() {
        return {
            // isSidebarOpen: true, // Sidebar başlangıçta açık
            header: "Program Editor",
            sideBarImg: "https://media.licdn.com/dms/image/v2/C560BAQFm6EBZkfL4zg/company-logo_200_200/company-logo_200_200/0/1630582502592/verus_event_management_it_solutions_logo?e=2147483647&v=beta&t=h8TyT4Y9kiUftIPUuMHJxdcLU_Noo-mAhuPi_Pphwnw",
            menuItems: [
                { name: "Home", link: "/home", icon: "fa-solid fa-house" },
                { name: "About", link: "/about", icon: "fa-solid fa-list" },
                { name: "Services", link: "/services", icon: "fa-solid fa-gears" },
                { name: "Contact", link: "/contact", icon: "fa-solid fa-phone-volume" },
            ],
        };
    },
    // methods: {
    //     toggleSidebar() {
    //         this.isSidebarOpen = !this.isSidebarOpen; // Sidebar durumunu tersine çevir
    //     },
    // },
};


</script>

<style scoped>
.icon-div {
    width: 12%;
    text-align: center;
    display: inline-block;
}
.icon-color {
    color: #5238C6;
}
.txt-div {
    width: 80%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}
.cls-btn {
    color:#b1b1b1;
    font-size: 28px;
    margin-right: 14px;
    text-decoration: none;
    cursor: pointer;
}
.w3-button {
    text-decoration: none;
    color: #b1b1b1;
    font-size: 14px;
}
.side-link ul {
    list-style-type: none;
    padding-left: 0;
}

.side-head {
    background: #30363D;
    color: #FFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
}
.head-img {
    width: 23px;
}
.w3-sidebar {
    height: 100vh;
    width: 15% !important;
    background-color: #21262E;
    position: fixed;
    display: block;
    transition: width 0.5s ease-in-out, display 0.5s ease-in-out; 
    z-index: 1054;
}

.cust-hvr {
    transition: all 200ms ease-in-out;
}
.cust-hvr:hover {
    padding-left: 8px;
    text-decoration: underline;
    color: #FFF
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");


</style>