<template>

    <div class="col-lg-3" v-for="user in users" :key="user.id">
        <div class="card custom-card-bg mb-3">
            <div class="card-body">
                <h5 class="card-title">{{ user.name }}</h5>
                <div>
                    Email: {{ user.email }}<br />
                    Şehir: {{ user.address.city }}<br />
                </div>
            </div>
            <div class="card-footer text-end">
                <a href="#" class="btn btn-sm modal-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <i class="fa-solid fa-plus me-2"></i>{{ cardBtnTxt }}
                </a>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content modal-bg">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Creating Item in Session</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="inputGroupSelect01" class="mb-0">Status</label>
                                <select class="form-select form-cust" id="inputGroupSelect01" v-model="StatusValue">
                                    <option selected>Choose...</option>
                                    <option value="1">Draft</option>
                                    <option value="2">Published</option>
                                    <option value="3">Archived</option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="f_1" class="mb-0">Title</label>
                                <input type="text" class="form-control form-cust" id="f_1" autocomplete="off" v-model="TitleValue">
                            </div>
                            <div class="col-md-6 mb-3" style="padding-top: 22px;">
                                <div class="form-cust rounded ps-3 pt-2 pb-2">
                                    <input class="form-check-input" type="checkbox" v-model="IsKeynoteValue" id="f_check">
                                    <label class="form-check-label ms-2" for="f_check">
                                        Enabled
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="inputPassword2" class="mb-0">Equipment Needs</label>
                                <input type="text" class="form-control form-cust" id="f_2" autocomplete="off" v-model="EquipmentValue">
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="inputPassword2" class="mb-0">Max. Attendees</label>
                                <input type="number" class="form-control form-cust" id="2" v-model="AttendeesValue">
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="inputGroupSelect02" class="mb-0">Types</label>
                                <select class="form-select form-cust" id="inputGroupSelect02" v-model="TypesValue">
                                    <option selected>Choose...</option>
                                    <option value="1">Oral Session</option>
                                    <option value="2">Poster Session</option>
                                    <option value="3">Panel Session</option>
                                    <option value="4">Workshop</option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="inputGroupSelect03" class="mb-0">Track</label>
                                <select class="form-select form-cust" id="inputGroupSelect03" v-model="TrackValue">
                                    <option selected>Choose...</option>
                                    <option value="1">Track 1</option>
                                    <option value="2">Track 2</option>
                                    <option value="3">Track 3</option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="inputGroupSelect04" class="mb-0">Hall</label>
                                <select class="form-select form-cust" id="inputGroupSelect04" v-model="HallValue">
                                    <option selected>Choose...</option>
                                    <option value="1">Hall 1</option>
                                    <option value="2">Hall 2</option>
                                    <option value="3">Hall 3</option>
                                </select>
                            </div>
                            <div class="col-md-12 mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label mb-0">Abstract</label>
                                <textarea class="form-control form-cust" id="exampleFormControlTextarea1" rows="3" v-model="TxtAreaValue"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal"><i class="fa-solid fa-xmark me-2"></i>Close</button>
                    <button type="button" class="btn btn-sm modal-save-btn" data-bs-dismiss="modal" @click="ShowAlert">
                        <i class="fa-solid fa-check me-2"></i>Save changes
                    </button>
                </div>
            </div>
        </div>
    </div>
<!-- Modal -->
</template>

<script>
import axios from 'axios';

export default {
    name: "HomePage",

    data() {
        return {
            cardTitle: "Kullanıcılar",
            cardBtnTxt: "Add Session",
            users: [],
            TitleValue: "",
            StatusValue: "",
            IsKeynoteValue: "",
            EquipmentValue: "",
            AttendeesValue: "",
            TypesValue: "",
            TrackValue: "",
            HallValue: "",
            TxtAreaValue: ""
        };
    },

    methods: {
        async fetchUsers() {
            const apiUrl = "https://jsonplaceholder.typicode.com/users";  //API URL'si

            try {
                const response = await axios.get(apiUrl);
                this.users = response.data;
            } catch (error) {
                console.error("Kullanıcı verisi alınırken hata oluştu:", error);
            }
        },

        ShowAlert() {
            alert(`TitleValue: ${this.TitleValue}
            \nStatusValue: ${this.StatusValue}
            \nIsKeynoteValue: ${this.IsKeynoteValue}
            \nEquipmentValue: ${this.EquipmentValue}
            \nAttendeesValue: ${this.AttendeesValue}
            \nTypesValue: ${this.TypesValue}
            \nTrackValue: ${this.TrackValue}
            \nHallValue: ${this.HallValue}
            \nTxtAreaValue: ${this.TxtAreaValue}`)
        },
    },

    mounted() {
        this.fetchUsers();  // Bileşen yüklendiğinde kullanıcıları getiriyoruz
    }
};
</script>

<style scoped>
.modal-save-btn {
    background-color: #5238C6;
    color: #FFF;
}

.modal-save-btn:hover {
    background-color: #674cdd;
    color: #FFF;
}

.form-cust {
    background-color: #0D1117 !important;
    border: 1px solid #313438;
    color: #FFF !important
}

.form-cust:focus {
    box-shadow: none !important;
    border: 1px solid #5238C6;
}

.modal-bg {
    background-color: #0D1117;
    color: #fff;
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.custom-card-bg {
    background-color: #0D1117;
    color: #fff;
}

.modal-btn {
    background-color: #5238C6;
    color: #fff;
}

.modal-btn:hover {
    background-color: #674cdd;
    color: #fff;
}
</style>