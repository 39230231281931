<template>
  <LeftMenu />
  <div class="container-fluid">
    <div id="main" class="row" style="">
      <div class="col-md-12 ps-0 pe-0">
        <HeaderPartial></HeaderPartial>
      </div>
      
      <div class="col-md-12 pt-4 ps-4">
        <div class="container-fluid">
          <div class="row">
            <router-view />
          </div>
        </div>

      </div>
    </div>
    <div class="fixed-bottom border text-center">
      <FooterPartial></FooterPartial>
    </div>
  </div>
  
</template>

<script>
import LeftMenu from "./components/LeftMenu.vue";
import HeaderPartial from "./components/HeaderPart.vue"
import FooterPartial from "./components/FooterPart.vue"

export default {
  name: "App",
  components: {
    LeftMenu,
    HeaderPartial,
    FooterPartial
  },
};
</script>

<style>
#main{
  padding-left: 15%;
  transition: padding-left 0.2s ease-in-out; 
}
</style>
