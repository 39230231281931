import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../HomePage.vue";
import AboutPage from "../AboutPage.vue";
import ContactPage from "../ContactPage.vue";
import ServicePage from "../ServicePage.vue";
import ServiceDetail from "../ServiceDetail.vue";

const routes = [
  { path: "/", redirect: "/home" },
  { path: "/home", name: "Home", component: HomePage, meta: { title: 'Home Page' } },
  { path: "/about", name: "About", component: AboutPage, meta: { title: 'About Page' } },
  { path: "/contact", name: "Contact", component: ContactPage, meta: { title: 'Contact Page' } },
  { path: "/services", name: "Service", component: ServicePage, meta: { title: 'Service Page' } },
  { path: "/services/detail/:id", name: ServiceDetail, component: ServiceDetail, meta: { title: 'Detail Page' } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
