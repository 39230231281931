<template>
    <nav class="navbar fixed-bottom custom-footer">
  <div class="container-fluid justify-content-center">
    <div class="text-white">
        <p>{{ footerTxt }} &copy;</p>
    </div>
  </div>
</nav>
  </template>
  
  <script>
  
  export default {
    name: "FooterPartial",
  
    data() {
      return {
        footerTxt: "Copyright 2058, Example Corporation"
      }
    },
    
  };
  </script>

  <style scoped>
     .custom-footer {
    background-color: #0D1117;
    color: #FFF;
  }

</style>