<template>
    <div>
        <div class="row">
            <div class="col-lg-3" v-for="post in paginatedPosts" :key="post.id">
                <div class="card custom-card-bg mb-3">
                    <div class="card-body">
                        <div class="title">
                            {{ post.title }}
                        </div>
                        <div class="card-footer text-end">
                            <router-link :to="`/services/detail/${post.id}`" class="btn btn-sm detail-btn">
                                <i class="fa-solid fa-arrow-right me-2"></i>Detail
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Sayfalama Kontrolleri -->
        <div class="pagination-controls text-center mt-4">
            <button @click="prevPage" :disabled="currentPage === 1">Prev</button>
            <span>Sayfa {{ currentPage }} / {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "ServicePage",

    data() {
        return {
            posts: [],
            currentPage: 1,
            itemsPerPage: 12
        };
    },

    computed: {
        // Sayfalama için gösterilecek veri dilimini döndürür
        paginatedPosts() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.posts.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.posts.length / this.itemsPerPage);
        }
    },

    methods: {
        async fetchPosts() {
            const apiUrl = "https://jsonplaceholder.typicode.com/posts";  //API URL'si

            try {
                const response = await axios.get(apiUrl);
                this.posts = response.data;
            } catch (error) {
                console.error("Post verisi alınırken hata oluştu:", error);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        }
    },

    mounted() {
        this.fetchPosts();  // Bileşen yüklendiğinde verileri getiriyoruz
    }
};
</script>

<style scoped>
.detail-btn {
    background-color: #5238C6;
    color: #fff;
}

.detail-btn:hover {
    background-color: #674cdd;
    color: #fff;
}

.custom-card-bg {
    background-color: #0D1117;
    color: #fff;
    min-height: 130px;
}

.pagination-controls button {
    background-color: #5238C6;
    color: #fff;
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination-controls button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}
</style>
