<template>
  <nav class="custom-navi p-3 d-flex align-items-center">
    
    <div class="container-fluid">
      <a class="w3-button w3-teal w3-xlarge text-dark" onclick="w3_open()"><i class="fa-solid fa-bars"></i></a>
      <h4 class="header d-inline-block ms-4 mb-0">{{ pageTitle }}</h4>
    </div>
      
  </nav>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: "HeaderPartial",

  data() {
    return {
    }
  },
  
  //Hangi link tıklantıysa sayfa başlığının değerini routerden alıyoruz
  setup() {
    const route = useRoute();

    const pageTitle = computed(() => route.meta.title || '');

    return { pageTitle };
  },

};
</script>

<style scoped>
  .custom-navi {
    background-color: #0D1117;
    color: #FFF;
    font-size: 22px;
  }

  .w3-teal {
    cursor: pointer;
    color: #fff !important;
}
</style>