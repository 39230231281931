<template>
    <div class="col-lg-3">
        <div class="card">
            <div class="card-body">
                <h3>Service Detail</h3>
                <p style="font-weight: 600;">Detail for ID: {{ id }}</p>
                <div>
                    <p>{{ serviceDetail.title }}</p>
                    <p>{{ serviceDetail.body }}</p>
                    <!-- Diğer detayları burada görüntüleyebilirsiniz -->
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';

export default {
    name: "ServiceDetail",
    data() {
        return {
            id: this.$route.params.id,
            serviceDetail: [],
        };
    },
    mounted() {
        this.fetchServiceDetail();
    },
    methods: {
        async fetchServiceDetail() {
            try {
                const response = await axios.get(`https://jsonplaceholder.typicode.com/posts/${this.id}`);
                this.serviceDetail = response.data;
                console.log("Service Detail:", this.serviceDetail);
            } catch (error) {
                console.error("Error fetching service detail:", error);
            }
        },
    },
};
</script>